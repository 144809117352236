import React from 'react'
import AuthProvider from './src/components/Context/AuthProvider'

// eslint-disable-next-line import/prefer-default-export
const Provider_Wrapper = ({element}) => (
  <AuthProvider>
    <span>{element}</span>
  </AuthProvider>
)

export default Provider_Wrapper