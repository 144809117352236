import jwtDecode from 'jwt-decode'
import wrapWithProvider from './wrap-with-provider'
import './src/styles/global.css'

if (localStorage.getItem('userToken')) {
  const bearerToken = localStorage.getItem('userToken')
  const decoded = jwtDecode(bearerToken)
  if (Date.now() >= decoded.exp * 1000) {
    console.log('removing token')
    localStorage.removeItem('userToken')
    // eslint-disable-next-line no-restricted-globals
    location.reload()
  }
} else {
  console.warn('no token found')
}
// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = wrapWithProvider
